<template>
  <div class="py-24 max-w-5xl mx-auto">
    <section class="px-4">
      <form-wizard>
        <form-step :processor="skipIfSelling">
          <!-- This example requires Tailwind CSS v2.0+ -->
          <fieldset>
            <legend class="text-base font-medium text-gray-800 md:text-lg">
              Welcome to Roofone! How can we help?
            </legend>

            <div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4 lg:gap-x-8">
              <!--
                Checked: "border-transparent", Not Checked: "border-gray-300"
                Active: "ring-2 ring-lime-600"
              -->
              <label
                v-for="(item, key) in objectives" :key="key"
                :for="`objective-${key}`"
                :class="[mainObj === key ? 'ring-2 ring-lime-600' : 'border-gray-300']"
                class="relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none"
              >
                <input
                  :id="`objective-${key}`"
                  type="radio"
                  required
                  v-model="mainObj"
                  name="objective"
                  :value="key"
                  class="sr-only"
                  aria-labelledby="project-type-0-label"
                  aria-describedby="project-type-0-description-0 project-type-0-description-1"
                />
                <div class="w-full flex gap-x-4">
                  <component
                    :class="[mainObj === key ? 'text-lime-600' : '']"
                    :is="item.icon" class="text-gray-500 h-12 w-auto md:h-16"
                  ></component>
                  <p id="" class="flex items-center text-gray-700">
                    {{ item.label }}
                  </p>
                </div>
                <!--
                  Not Checked: "invisible"

                  Heroicon name: solid/check-circle
                -->
                <svg :class="[mainObj === key ? '' : 'invisible']" class="h-5 w-5 text-lime-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                </svg>
                <!--
                  Active: "border", Not Active: "border-2"
                  Checked: "border-lime-600", Not Checked: "border-transparent"
                -->
                <div
                  :class="[mainObj === key ? 'border border-lime-600' : 'border-2 border-transparent']"
                  class="absolute -inset-px rounded-lg pointer-events-none"
                  aria-hidden="true"
                ></div>
              </label>

            </div>
          </fieldset>

          <div class="mt-12 grid grid-cols-1 gap-4 md:mt-24 md:flex md:ml-auto md:flex-row-reverse">
            <button type="submit" class="w-full inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-lime-600 md:w-auto hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500">Next &rarr;</button>
          </div>
        </form-step>

        <form-step>
          <fieldset>
            <legend class="text-lg font-medium text-gray-800">Where would you like to buy? (Max. 3)</legend>
            <div class="mt-4 border-t border-b border-gray-200 py-6">
              <div class="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4">

                <label
                  v-for="(location, key) in locations"
                  :key="`location-${key}`"
                  :class="[preferredLocations.includes(location) ? 'ring-2 ring-lime-600' : '']"
                  class="block cursor-pointer px-4 py-3 border border-gray-300 md:rounded"
                  :for="`location-option-${key}`"
                >
                  <div class="w-full flex items-center justify-between">
                    <span class="text-gray-700 font-medium">{{ location }}</span>
                    <input
                      :id="`location-option-${key}`"
                      v-model="preferredLocations"
                      name="preferred_locations"
                      type="checkbox"
                      :value="location"
                      class="focus:ring-lime-500 h-4 w-4 text-lime-600 border-gray-300 rounded"
                    />
                  </div>
                </label>

              </div>
            </div>
          </fieldset>

          <div class="mt-12 grid grid-cols-1 gap-4 md:mt-24 md:flex md:ml-auto md:flex-row-reverse">
            <button type="submit" class="w-full inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-lime-600 md:w-auto hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500">Next &rarr;</button>
            <button type="button" class="w-full inline-flex items-center justify-center px-4 py-2 border border-gray-300 go-back shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white md:w-auto hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500">Previous</button>
          </div>
        </form-step>

        <form-step>
          <div>
            <div class="flex items-center justify-between">
              <h2 class="text-lg font-medium text-gray-800">When are you looking to buy?</h2>
            </div>

            <fieldset class="mt-8">
              <legend class="sr-only">Choose a time frame</legend>
              <div class="grid grid-cols-2 gap-3 sm:grid-cols-3 lg:grid-cols-6">
                <!--
                  In Stock: "cursor-pointer", Out of Stock: "opacity-25 cursor-not-allowed"
                  Active: "ring-2 ring-offset-2 ring-grey-500"
                  Checked: "bg-grey-600 border-transparent text-white hover:bg-grey-700", Not Checked: "bg-white border-gray-200 text-gray-900 hover:bg-gray-50"
                -->
                <label
                  v-for="(value, key) in timeFrames"
                  :key="`time-frame-${key}`"
                  :class="[timeframe === value ? 'ring-2 ring-offset-2 ring-grey-500 bg-grey-600 border-transparent text-white hover:bg-grey-700': 'bg-white border-gray-200 text-gray-900 hover:bg-gray-50']"
                  class="border rounded-md py-3 px-3 flex items-center justify-center text-sm font-medium sm:flex-1 cursor-pointer focus:outline-none"
                >
                  <input type="radio" v-model="timeframe" name="timeframe" :value="value" class="sr-only" required :aria-labelledby="`time-frame-option-${key}-label`">
                  <p :id="`time-frame-option-${key}-label`">{{ value }}</p>
                </label>
              </div>
            </fieldset>
          </div>

          <div class="mt-12 grid grid-cols-1 gap-4 md:mt-24 md:flex md:ml-auto md:flex-row-reverse">
            <button type="submit" class="w-full inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-lime-600 md:w-auto hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500">Next &rarr;</button>
            <button type="button" class="w-full inline-flex items-center justify-center px-4 py-2 border border-gray-300 go-back shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white md:w-auto hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500">Previous</button>
          </div>
        </form-step>

        <form-step>
          <div>
            <div class="flex items-center justify-between">
              <h2 class="text-lg font-medium text-gray-800">What's your budget range?</h2>
            </div>

            <fieldset class="mt-8">
              <legend class="sr-only">Choose a budget range</legend>
              <div class="grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-5">
                <!--
                  In Stock: "cursor-pointer", Out of Stock: "opacity-25 cursor-not-allowed"
                  Active: "ring-2 ring-offset-2 ring-grey-500"
                  Checked: "bg-grey-600 border-transparent text-white hover:bg-grey-700", Not Checked: "bg-white border-gray-200 text-gray-900 hover:bg-gray-50"
                -->
                <label
                  v-for="(value, key) in budgetRanges"
                  :key="`budget-range-${key}`"
                  :class="[budgetRange === value ? 'ring-2 ring-offset-2 ring-grey-500 bg-grey-600 border-transparent text-white hover:bg-grey-700': 'bg-white border-gray-200 text-gray-900 hover:bg-gray-50']"
                  class="border rounded-md py-3 px-3 flex items-center justify-center text-sm font-medium sm:flex-1 cursor-pointer focus:outline-none"
                >
                  <input type="radio" v-model="budgetRange" name="budget_range" :value="value" class="sr-only" required :aria-labelledby="`budget-range-option-${key}-label`">
                  <p :id="`budget-range-option-${key}-label`">{{ value }}</p>
                </label>
              </div>
            </fieldset>
          </div>

          <div class="mt-12 grid grid-cols-1 gap-4 md:mt-24 md:flex md:ml-auto md:flex-row-reverse">
            <button type="submit" class="w-full inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-lime-600 md:w-auto hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500">Next &rarr;</button>
            <button type="button" class="w-full inline-flex items-center justify-center px-4 py-2 border border-gray-300 go-back shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white md:w-auto hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500">Previous</button>
          </div>
        </form-step>

        <financing-step :processor="processor" />

        <form-step>
          <page-spinner/>
        </form-step>
      </form-wizard>
    </section>
  </div>
</template>

<script>
import FormStep from '../../components/FormWizard/FormStep.vue'
import FormWizard from '../../components/FormWizard/FormWizard.vue'
import CalculatorIcon from '../../components/icons/CalculatorIcon.vue'
import ScaleIcon from '../../components/icons/ScaleIcon.vue'
import TagIcon from '../../components/icons/TagIcon.vue'
import PageSpinner from '../../components/PageSpinner.vue'
import { getCurrentUser } from '../../firebase-auth'
import FinancingStep from './FinancingStep.vue'

export default {
  components: {
    FormWizard,
    FormStep,
    FinancingStep,
    PageSpinner
  },

  data() {
    return {
      objectives: {
        buy: { icon: CalculatorIcon, label: 'I want to buy a house' },
        sell: { icon: TagIcon, label: 'I want to sell a house' },
        both: { icon: ScaleIcon, label: 'I want to do BOTH'},
      },
      timeFrames: ['This month', '1 - 3 months', '3 - 6 months', '6 - 12 months', '12+ months'],
      budgetRanges: ['less than ₦50m', '₦50m - ₦100m', '₦100m - ₦150m', '₦150m - ₦200m', 'more than ₦200m'],

      mainObj: '',
      timeframe: null,
      preferredLocations: [],
      budgetRange: null,
    }
  },

  created() {
    if (this.$store.state.locations === null) {
      return this.$store.dispatch('getLocations');
    }
  },

  computed: {
    locations() {
      const { locations } = this.$store.state

      return locations ? locations : []
    }
  },

  methods: {
    skipIfSelling(data, wizard) {
      if (this.mainObj !== 'sell') {
        return wizard.goForward()
      }
      this.submitForm(wizard.getAllValuesAsJSON())

      wizard.goTo(wizard.steps.length - 1, wizard.currentStep)
    },

    submitForm(data) {
      const apiRoot = process.env.VUE_APP_API_ROOT;

      return getCurrentUser()
        .then(user => {
          fetch(`${apiRoot}/auth/onboard`, {
            method: 'post',
            body: JSON.stringify(data),
            headers: {
              Authorization: `Bearer ${user.accessToken}`
            }
          })
            .then(response => {
              if (response.ok) {
                this.$router.push('/')
              }

              throw new Error('Customer onboarding failed.')
            })
            .catch(console.log)
        })
    },

    processor(data, wizard) {
      this.submitForm(wizard.getAllValuesAsJSON())

      // going forward whatever the case in order to show the loading page
      wizard.goForward()
    },
  }
}
</script>
