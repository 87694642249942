/* eslint-disable */
<template>
  <section>
    <slot></slot>
  </section>
</template>

<script>
import {
  COMPONENT_NAME as stepComponentName,
  SHOW_EVENT as stepShowEvent,
  HIDE_EVENT as stepHideEvent
} from './FormStep.vue';

export default {
  /*
   * Wizard has multiple steps
   *
   * Wizard loads steps on init

   * Wizard saves form values for each step
   * At the end of the process, wizard provides all the form data
   * as a FormData object.
   *
   * Steps parent must be the wizard.
   * Steps register handlers for previous and next on mount.
   * Steps run the processors on submit and call wizard to change step.
   * Steps call wizard to signal backward movement
   */
  props: {
    controller: {
      required: false,
    },
  },

  data() {
    return {
      blurClass: 'opacity-50',
      currentStep: 0,
      steps: [],
    }
  },

  /**
   * @returns {void}
   */
  mounted () {
    /**
     * Three-levels of children checks:
     *
     * If any of this component's children is a form step, add it.
     * If any of this component's grandChildren is a form step, add it.
     * If any of this component's greatGrandChildren is a form step, add it.
     * And that's it; three levels.
     *
     * @todo Might be time to start thinking about a cleaner implementation
     * for this tho. To support {x} levels of child component checks.
     * Probably with {x} being configurable.
     *
     * No continuous recursion for now.
     */
    const childrenCount = this.$children.length;

    for (let i = 0; i < childrenCount; i++) {
      const child = this.$children[i];

      if (child.$options.name === stepComponentName) {
        this.registerStep(child);

        // we've found the form-step component (there can only be one)
        // in this section of the tree. Move on.
        continue;
      }

      const grandChildrenCount = child.$children.length;

      for (let j = 0; j < grandChildrenCount; j++) {
        const grandChild = child.$children[j];

        if (grandChild.$options.name === stepComponentName) {
          this.registerStep(grandChild);
          continue;
        }

        const greatGrandChildrenCount = grandChild.$children.length;
        for (let k = 0; k < greatGrandChildrenCount; k++) {
          const greatGrandChild = grandChild.$children[k];

          if (greatGrandChild.$options.name == stepComponentName) {
            this.registerStep(greatGrandChild);
          }
        }
      }
    }

    this.$emit('update:controller', this);
  },

  methods: {
    /**
     * Register a form-step with the form wizard.
     *
     * @param {Vue} step
     *
     * @returns {void}
     */
    registerStep (step) {
      // show the first step
      if (this.steps.length === 0) {
        step.$emit(stepShowEvent);
      }

      step.setWizard(this);
      this.steps.push(step);
    },

    /**
     * Move backward within the form wizard.
     *
     * @returns {void}
     */
    goBack () {
      const newStep = this.currentStep - 1;

      return this.goTo(newStep, this.currentStep);
    },

    /**
     * Move forward within the form wizard.
     *
     * @returns {void}
     */
    goForward () {
      const newStep = this.currentStep + 1;

      return this.goTo(newStep, this.currentStep);
    },

    /**
     * Go to specific (zero-indexed) step within the form wizard,
     * and update the current step.
     *
     * @param {number} destination - Where we're going
     * @param {number} source - Where we're coming from
     *
     * @returns {void}
     */
    goTo (destination, source) {
      const movingForward = destination > source;

      // if the destination step is unregistered, our destination needs an update.
      const destinationStep = this.steps[destination];
      if (!destinationStep.registered) {
        destination += movingForward ? 1 : -1;
      }

      if (destination === source) {
        return;
      }

      // throw an error if the requested destination is out of bounds
      if ((destination >= this.steps.length) || (destination < 0)) {
        throw new Error(
          `[FormWizard] Can't go to form step ${destination} <zero-indexed>:
           only ${this.steps.length} step(s) available.`
        );
      }

      let toShow, toHide = null;
      // hide all the steps
      this.steps.forEach((step, index) => {
        // const element = step.$el;
        if (step.isActive()) {
          toHide = step;
        }

        // but show the destination step
        if (index === destination) {
          toShow = step;

          // if (movingForward) {
          //   element.scrollIntoView({ behavior: 'smooth' });
          // }
        }
      })

      toHide.$emit(stepHideEvent, movingForward).$nextTick(
        () => setTimeout(() => toShow.$emit(stepShowEvent, movingForward), 300)
      );

      // update the current step
      this.currentStep = destination;
    },

    /**
     * Compiles the values from all the form steps, returning
     * them in a single `FormData` object.
     *
     * @returns {FormData}
     */
    getAllValuesAsFormData() {
      const values = new FormData();

      for (let i = 0; i < this.steps.length; i++) {
        const data = new FormData(this.steps[i].$el);

        for (const [field, val] of data.entries()) {
          values.append(field, val);
        }
      }

      return values;
    },

    getAllValuesAsJSON() {
      let values = {};

      this.steps.forEach((step) => {
        values = Object.assign(values, step.getValuesJSON());
      });

      return values;
    },
  }
}
</script>
