<template>
  <form-step :processor="processor">
    <div>
      <div class="flex items-center justify-between">
        <h2 class="text-lg font-medium text-gray-800">Will you be needing financing?</h2>
      </div>

      <fieldset class="mt-8">
        <legend class="sr-only"></legend>
        <div class="grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-5">
          <!--
            In Stock: "cursor-pointer", Out of Stock: "opacity-25 cursor-not-allowed"
            Active: "ring-2 ring-offset-2 ring-grey-500"
            Checked: "bg-grey-600 border-transparent text-white hover:bg-grey-700", Not Checked: "bg-white border-gray-200 text-gray-900 hover:bg-gray-50"
          -->
          <label
            v-for="(value, key) in financingChoices"
            :key="`financing-choice-${key}`"
            :class="[needsFinancing === key ? 'ring-2 ring-offset-2 ring-grey-500 bg-grey-600 border-transparent text-white hover:bg-grey-700': 'bg-white border-gray-200 text-gray-900 hover:bg-gray-50']"
            class="border rounded-md py-3 px-3 flex items-center justify-center text-sm font-medium sm:flex-1 cursor-pointer focus:outline-none"
          >
            <input type="radio" v-model="needsFinancing" name="needs_financing" :value="key" class="sr-only" required :aria-labelledby="`financing-choice-option-${key}-label`">
            <p :id="`financing-choice-option-${key}-label`">{{ value }}</p>
          </label>
        </div>
      </fieldset>

      <fieldset v-show="needsFinancing === 'yes'" class="mt-12 transition ease-in-out duration-200">
        <legend class="text-base font-medium text-gray-800">
          How much will you be willing to put up as a down payment?
        </legend>
        <div class="mt-4 grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-5">
          <!--
            In Stock: "cursor-pointer", Out of Stock: "opacity-25 cursor-not-allowed"
            Active: "ring-2 ring-offset-2 ring-grey-500"
            Checked: "bg-grey-600 border-transparent text-white hover:bg-grey-700", Not Checked: "bg-white border-gray-200 text-gray-900 hover:bg-gray-50"
          -->
          <label
            v-for="(value, key) in downPaymentOptions"
            :key="`downpayment-option-${key}`"
            :class="[downPayment === value ? 'ring-2 ring-offset-2 ring-grey-500 bg-grey-600 border-transparent text-white hover:bg-grey-700': 'bg-white border-gray-200 text-gray-900 hover:bg-gray-50']"
            class="border rounded-md py-3 px-3 flex items-center justify-center text-sm font-medium sm:flex-1 cursor-pointer focus:outline-none"
          >
            <input type="radio" v-model="downPayment" name="proposed_downpayment" :value="value" class="sr-only" :required="needsFinancing === 'yes'" :aria-labelledby="`downpayment-option-${key}-label`">
            <p :id="`downpayment-option-${key}-label`">{{ value }}</p>
          </label>
        </div>
      </fieldset>
    </div>

    <div class="mt-12 grid grid-cols-1 gap-4 md:mt-24 md:flex md:ml-auto md:flex-row-reverse">
      <button type="submit" class="w-full inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-lime-600 md:w-auto hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500">Finish &rarr;</button>
      <button type="button" class="w-full inline-flex items-center justify-center px-4 py-2 border border-gray-300 go-back shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white md:w-auto hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500">Previous</button>
    </div>
  </form-step>
</template>

<script>
import FormStep from '../../components/FormWizard/FormStep.vue'

export default {
  components: { FormStep },
  props: {
    processor: {
      type: Function,
      required: true,
    }
  },

  data() {
    return {
      financingChoices: {
        yes: 'Yes please',
        maybe: 'Maybe, not sure',
        no: 'No, thanks'
      },

      downPaymentOptions: ['less than 20%', '20 - 40%', '40% or more'],

      needsFinancing: '',
      downPayment: '',
    }
  },
}
</script>
