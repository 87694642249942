<template>
  <transition
    enter-class="opacity-0"
    enter-active-class="ease-out duration-300"
    enter-to-class="opacity-100"
    leave-class="opacity-100"
    leave-active-class="ease-in duration-200"
    leave-to-class="opacity-0"
  >
    <form @submit.prevent="moveForward" v-show="isActive()" class="form-wizard-form-step">
      <slot></slot>
    </form>
  </transition>
</template>

<script>
import formToJSON from './formToJSON';

export const COMPONENT_NAME = 'FormStep';

export const SHOW_EVENT = 'FormStep.show';
export const HIDE_EVENT = 'FormStep.hide';

export default {
  name: COMPONENT_NAME,
  props: {
    processor: Function,
    rules: Object,
    registered: {
      type: Boolean,
      required: false,
      default: true,
    }
  },

  data() {
    return {
      shown: false,

      movingForward: null,
    }
  },

  created() {
    this.$on(SHOW_EVENT, forward => { this.shown = true; this.movingForward = forward })
    this.$on(HIDE_EVENT, forward => { this.shown = false; this.movingForward = forward })
  },

  mounted() {
  },

  methods: {
    /**
     * Called by the parent FormWizard, used to pass
     * a reference to the wizard to this component.
     *
     * @param {Vue} wizard
     *
     * @returns {void}
     */
    setWizard(wizard) {
      this.$wizard = wizard;

      const backButton = this.$el.querySelector('button.go-back');

      if (backButton) {
        backButton.addEventListener('click', this.$wizard.goBack);
      }
    },

    moveForward () {
      // collect data
      const values = this.getValues();

      // validate if necessary
      if (this.rules) {
        const errors = this.errors(values, this.rules);

        if (errors) {
          this.$emit('validate', errors);
        }
      }

      // call processor if available
      if (this.processor) {
        this.processor(values, this.$wizard, this);

        return;
        // 'cause the processor is now in charge
        // of moving forward
      }

      // move forward if it's all good
      this.$wizard.goForward();
    },

    getValues() {
      return new FormData(this.$el);
    },

    /**
     * @returns {Object|null}
     */
    errors(data, rules) {
      const errors = {};

      data.forEach((value, key) => {
        const fieldErrors = this.validateField(rules[key], value);

        if (fieldErrors.length) {
          errors[key] = fieldErrors;
        }
      });

      return Object.keys(errors).length > 0 ? errors : null;
    },

    /**
     * @returns {Array<string>}
     */
    validateField(rules, value, errors = []) {
      const defaultMessage = 'This field is invalid';

      if (typeof rules === 'function') {
        if (!rules(value)) {
          if (!errors.includes(defaultMessage)) {
            errors.push(defaultMessage);
          }
        }

        return errors;
      }

      if (Array.isArray(rules) && typeof rules[0] === 'function') {
        const [validate, message] = rules;

        if (!validate(value)) {
          if (message) {
            errors.push(message);
          }

          if (!message && !errors.includes(defaultMessage)) {
            errors.push(defaultMessage);
          }
        }

        return errors;
      }

      if (Array.isArray(rules)) {
        for (let i = 0; i < rules.length; i++) {
          errors.push(...(this.validateField(rules[i], value, errors)));
        }
      }

      return errors;
    },

    getValuesJSON() {
      return formToJSON(this.$el.elements);
    },

    isActive() {
      return this.registered && this.shown;
    }
  }
}
</script>
